<template>
  <q-dialog :ref="$options.name">
    <q-card flat style="width: 550px; max-width: 85vw;">
      <q-table
        flat
        square
        :rows="tags"
        :columns="columns"
        :filter="filter"
        row-key="id"
        :selection="addOnly ? 'none' : 'multiple'"
        v-model:selected="selected"
        binary-state-sort
        :hide-bottom="true"
        style="border: none !important;"
        :pagination="{
          page: 1,
          rowsPerPage: 100
        }"
      >
        <template v-slot:top>
          <div
            class="full-width row justify-between items-center q-px-md q-pt-sm q-pb-none"
          >
            <h5 class="q-ma-none text-weight-bold">
              Offer Tags Editor
            </h5>
            <div class="row">
              <q-input
                dense
                type="search"
                debounce="300"
                v-model="filter"
                placeholder="Search"
                class="q-ml-lg"
              >
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
            </div>
          </div>
        </template>

        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th v-if="!addOnly" align="left">
              <q-checkbox v-model="props.selected" />
            </q-th>
            <q-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </q-th>
            <q-th></q-th>
          </q-tr>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td v-if="!addOnly">
              <q-checkbox v-model="props.selected" />
            </q-td>
            <q-td v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.value }}
            </q-td>
            <q-td>
              <q-btn-dropdown
                v-if="!addOnly"
                flat
                rounded
                fab-mini
                style="min-height: 2.5em !important;min-width: 2.5em !important;padding:0 !important;"
                color="dark"
                dropdown-icon="more_horiz"
                no-icon-animation
                auto-close
              >
                <q-list>
                  <q-item clickable @click="updateTag(props.row)">
                    <q-item-section>
                      <q-item-label>Edit</q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item clickable @click="promptDeleteTag(props.row.id)">
                    <q-item-section>
                      <q-item-label>Delete</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </q-td>
          </q-tr>
        </template>
      </q-table>

      <q-card-actions align="between" class="q-gutter-x-md q-pa-md">
        <div>
          <q-btn flat color="primary" label="Add New" @click="createTag" />
          <q-btn
            v-if="!addOnly"
            flat
            color="subtle"
            label="Delete"
            class="q-ml-md"
            :disable="selected.length === 0"
            @click="promptDeleteTag()"
          ></q-btn>
        </div>
        <q-btn
          flat
          label="Close"
          color="subtle"
          v-close-popup
          :disable="submitting"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="tagEditor.show" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div class="text-h6">
          {{ tagEditor.title }}
        </div>
      </q-card-section>

      <q-form @submit="submitTagEditor">
        <q-card-section class="q-pt-none">
          <q-input
            dense
            autofocus
            v-model="tagEditor.tag"
            lazy-rules
            :rules="[
              val => (val && val.length > 1) || 'Enter an Offer Tag.',
              val =>
                (val &&
                  (!tagsLibraryTags.includes(val) ||
                    !tagEditorHasUnsavedChanges)) ||
                'Offer Tag already exists.'
            ]"
            :disable="tagEditor.submitting"
          />
        </q-card-section>

        <q-card-actions align="between">
          <q-btn flat color="subtle" label="Cancel" v-close-popup />
          <div>
            <q-tooltip
              v-if="!tagEditorHasUnsavedChanges"
              anchor="center left"
              self="center right"
            >
              No edits have been made.
            </q-tooltip>
            <q-btn
              type="submit"
              color="primary"
              label="Save Changes"
              :disable="tagEditor.submitting || !tagEditorHasUnsavedChanges"
              :loading="tagEditor.submitting"
            />
          </div>
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { Dialog } from "quasar";
import ConfirmationModalDelete from "@/components/UI/ConfirmationModalDelete";

export default {
  name: "OfferTagsLibraryEditorModal",
  props: {
    addOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["ok", "hide"],
  data() {
    return {
      submitting: false,
      columns: [
        /*{
          name: "id",
          label: "ID",
          field: "id",
          align: "left",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b)
        },*/
        {
          name: "tag",
          label: "Offer Tag",
          field: "tag",
          align: "left",
          sortable: true
        }
      ],
      filter: "",
      selected: [],
      tagEditor: {
        show: false,
        id: 0,
        tag: "",
        initialTag: "",
        title: "Add New Offer Tag"
      }
    };
  },
  computed: {
    tags() {
      return this.$store.state.offers.tagsLibrary.list;
    },
    tagsById() {
      return this.$store.getters["offers/tagsLibrary/byId"];
    },
    tagsLibraryTags() {
      return this.$store.state.offers.tagsLibrary.list.map(tagData => {
        return tagData.tag;
      });
    },
    tagEditorHasUnsavedChanges() {
      if (this.tagEditor.tag === "" && this.tagEditor.id === 0) {
        return true;
      } else {
        return this.tagEditor.initialTag !== this.tagEditor.tag;
      }
    }
  },
  mounted() {
    this.$store.dispatch("offers/tagsLibrary/POPULATE_LIST");
  },
  methods: {
    show() {
      this.$refs[this.$options.name].show();
    },
    hide() {
      this.$refs[this.$options.name].hide();
    },
    createTag() {
      this.promptTagEditor();
    },
    updateTag(tagData) {
      this.promptTagEditor(tagData);
    },
    promptDeleteTag(id = 0) {
      let idsToDelete = [];
      if (id !== 0) {
        idsToDelete = [id];
      } else {
        idsToDelete = this.selected.map(tag => {
          return tag.id;
        });
      }

      Dialog.create({
        component: ConfirmationModalDelete,
        componentProps: {
          descriptor:
            "Offer Tag(s) " +
            idsToDelete
              .map(tagId => {
                return this.tagsById[tagId].tag;
              })
              .join(", ")
        }
      }).onOk(dialog => {
        dialog.submitting = true;
        this.$store
          .dispatch("offers/tagsLibrary/POST_REQUEST", {
            endpoint: "delete",
            params: { id: idsToDelete }
          })
          .then(() => {
            this.triggerActionOutcomeAlert("delete", true);
            this.$store
              .dispatch("offers/tagsLibrary/POPULATE_LIST")
              .finally(() => {
                dialog.submitting = false;
                this.selected = [];
                dialog.hide();
              });
          })
          .catch(() => {
            this.triggerActionOutcomeAlert("delete", false);
            dialog.submitting = false;
          });
      });
    },
    promptTagEditor(tagData = {}) {
      if (Object.keys(tagData).length > 0) {
        this.tagEditor.id = tagData.id;
        this.tagEditor.tag = tagData.tag;
        this.tagEditor.initialTag = tagData.tag;
        this.tagEditor.title = "Edit Offer Tag";
      } else {
        this.tagEditor.id = 0;
        this.tagEditor.tag = "";
        this.tagEditor.initialTag = "";
        this.tagEditor.title = "Add New Offer Tag";
      }

      this.tagEditor.show = true;
    },
    submitTagEditor() {
      let endpoint = this.tagEditor.id === 0 ? "create" : "update";
      let params = {
        tag: this.tagEditor.tag
      };
      if (endpoint === "update") {
        params.id = this.tagEditor.id;
      }

      this.$store
        .dispatch("offers/tagsLibrary/POST_REQUEST", {
          endpoint,
          params
        })
        .then(() => {
          this.triggerActionOutcomeAlert(endpoint, true);
          this.$store
            .dispatch("offers/tagsLibrary/POPULATE_LIST")
            .finally(() => {
              this.tagEditor.show = false;
              this.selected = [];
              this.$emit("ok", this);
            });
        })
        .catch(() => {
          this.triggerActionOutcomeAlert(endpoint, false);
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
