<template>
  <div>
    <div
      v-if="
        (type === 'edit' && !editOfferPopulated) ||
          (type === 'create' &&
            templateOfferId.length > 0 &&
            !templateOfferPopulated)
      "
      class="row justify-center items-center"
    >
      <q-spinner color="primary" size="3em" />
    </div>
    <div v-else>
      <q-form @submit="$emit('submit', offer)">
        <q-card flat bordered>
          <q-card-section>
            <h5>Offer Details</h5>
          </q-card-section>
          <q-card-section>
            <div class="q-gutter-y-md">
              <q-input
                filled
                v-model="offer.name"
                label="Offer Name"
                lazy-rules
                :rules="[
                  val => (val && val.length > 1) || 'Enter an Offer Name.'
                ]"
                :disable="submitting"
                :hint="
                  type === 'create'
                    ? 'Add Offer ID to the name by inserting {offerId}.'
                    : ''
                "
              />

              <q-select
                filled
                v-model="offer.brand_id"
                :options="brandOptions"
                label="Brand"
                emit-value
                map-options
                lazy-rules
                :rules="[val => (val && val.length > 0) || 'Select a Brand.']"
                :disable="submitting"
              />

              <q-select
                filled
                v-model="offer.channel_id"
                :options="channelOptions"
                label="Channel"
                emit-value
                map-options
                lazy-rules
                :rules="[val => (val && val.length > 0) || 'Select a Channel.']"
                :disable="submitting"
              />

              <q-select
                filled
                v-model="offer.vertical_id"
                :options="verticalOptions"
                label="Vertical"
                emit-value
                map-options
                lazy-rules
                :rules="[
                  val => (val && val.length > 0) || 'Select a Vertical.'
                ]"
                :disable="submitting"
              />

              <q-select
                filled
                v-model="offer.campaign_type_id"
                :options="campaignTypesOptions"
                label="Campaign Type"
                emit-value
                map-options
                lazy-rules
                :rules="[
                  val => (val && val.length > 0) || 'Select a Campaign Type.'
                ]"
                :disable="submitting"
              />

              <q-input
                filled
                v-model="offer.external_campaign_id"
                label="External Campaign ID (Optional)"
                :disable="submitting"
                hint=""
              />

              <q-select
                filled
                v-model="offer.country_code"
                :options="countryOptions"
                label="Country"
                emit-value
                map-options
                lazy-rules
                :rules="[val => (val && val.length > 0) || 'Select a Country.']"
                :disable="submitting"
              />

              <div
                class="row justify-end"
                style="margin-top: 0; margin-bottom: -14px"
              >
                <a
                  class="q-pa-none q-ma-none"
                  href="javascript:void(0)"
                  @click="promptOfferTagsLibraryEditor"
                >
                  <small>Add New Tags</small>
                </a>
              </div>
              <q-select
                filled
                multiple
                use-chips
                v-model="offer.tags"
                :options="tagsOptions"
                label="Tags (Optional)"
                emit-value
                map-options
                :disable="submitting"
                :hint="offer.tags.length + ' Selected'"
              />
            </div>
          </q-card-section>
        </q-card>

        <FormActionsSection>
          <template v-slot:primary-actions>
            <q-btn
              :label="type === 'create' ? 'Create Offer' : 'Update Offer'"
              type="submit"
              color="primary"
              :disable="submitting"
              :loading="submitting"
            />
            <q-btn
              flat
              label="Cancel"
              to="/manage/offers"
              color="subtle"
              :disable="submitting"
            />
          </template>
          <template v-slot:secondary-actions>
            <q-btn
              v-if="type === 'edit'"
              round
              flat
              color="subtle"
              icon="delete"
              @click="promptDeleteOffer(offer)"
            >
              <q-tooltip anchor="center left" self="center right">
                Delete Offer
              </q-tooltip>
            </q-btn>
          </template>
        </FormActionsSection>
        <div class="q-mt-md q-gutter-x-md"></div>
      </q-form>
    </div>
  </div>
</template>

<script>
import { Dialog } from "quasar";
import ConfirmationModalDelete from "@/components/UI/ConfirmationModalDelete";
import FormActionsSection from "@/components/UI/FormActionsSection";
import OfferTagsLibraryEditorModal from "@/components/OfferTagsLibraryEditorModal";

export default {
  name: "OfferForm",
  components: { FormActionsSection },
  props: {
    type: {
      type: String,
      required: true
    },
    offerId: {
      type: String,
      required: false
    },
    templateOfferId: {
      type: String,
      required: false
    },
    submitting: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      offer: {
        name: "",
        brand_id: "",
        vertical_id: "",
        campaign_type_id: "",
        channel_id: "",
        external_campaign_id: null,
        country_code: "US",
        tags: []
      },
      brandOptions: [],
      channelOptions: [],
      verticalOptions: [],
      campaignTypesOptions: [],
      tagsOptions: [],
      countryOptions: [
        { label: "United States of America", value: "US" },
        { label: "Australia", value: "AU" },
        { label: "Canada", value: "CA" },
        {
          label: "United Kingdom of Great Britain and Northern Ireland",
          value: "GB"
        }
      ]
    };
  },
  computed: {
    lookupOfferId() {
      if (this.type === "edit" && this.offerId.length > 0) {
        return this.offerId;
      } else if (this.type === "create" && this.templateOfferId.length > 0) {
        return this.templateOfferId;
      }
      return "0";
    },
    editOfferPopulated() {
      return typeof this.offer.id !== "undefined";
    },
    templateOfferPopulated() {
      return this.offer.name.length > 0;
    }
  },
  mounted() {
    // TODO: Change to parallel requests.

    this.$store.dispatch("brands/POPULATE_LIST").finally(() => {
      this.brandOptions = this.generateSelectOptionsArray(
        this.$store.state.brands.list,
        "name",
        "id"
      );

      this.$store.dispatch("channels/POPULATE_LIST").finally(() => {
        this.channelOptions = this.generateSelectOptionsArray(
          this.$store.state.channels.list,
          "name",
          "id"
        );

        this.$store.dispatch("verticals/POPULATE_LIST").finally(() => {
          this.verticalOptions = this.generateSelectOptionsArray(
            this.$store.state.verticals.list,
            "name",
            "id"
          );

          this.$store
            .dispatch("offers/campaignTypes/POPULATE_LIST")
            .finally(() => {
              this.campaignTypesOptions = this.generateSelectOptionsArray(
                this.$store.state.offers.campaignTypes.list,
                "name",
                "id"
              );

              this.$store
                .dispatch("offers/tagsLibrary/POPULATE_LIST")
                .finally(() => {
                  this.tagsOptions = this.generateSelectOptionsArray(
                    this.$store.state.offers.tagsLibrary.list,
                    "tag",
                    "id"
                  );

                  if (this.lookupOfferId !== "0") {
                    this.getOffer(this.lookupOfferId);
                  }
                });
            });
        });
      });
    });
  },
  methods: {
    getOffer(offerId) {
      let offerTags = [];

      this.$store
        .dispatch("offers/tags/GET_REQUEST", {
          params: {
            offer_id: offerId
          }
        })
        .then(tags => {
          if (tags.length > 0) {
            offerTags = tags.map(tag => {
              return tag.tag_id;
            });
          }
        })
        .finally(() => {
          this.$store
            .dispatch("offers/GET_REQUEST", {
              params: {
                id: offerId
              }
            })
            .then(data => {
              if (data.length > 0) {
                let offer = data[0];
                this.offer = {
                  name: offer.name,
                  brand_id: offer.brand_id,
                  vertical_id: offer.vertical_id,
                  campaign_type_id: offer.campaign_type_id,
                  channel_id: offer.channel_id,
                  tags: offerTags,
                  country_code: offer.country_code
                };
                if (this.type === "edit") {
                  this.offer.id = offer.id;
                  this.offer.external_campaign_id = offer.external_campaign_id;
                }
              }
            });
        });
    },
    promptDeleteOffer(offer) {
      Dialog.create({
        component: ConfirmationModalDelete,
        componentProps: {
          descriptor: offer.name
        }
      }).onOk(dialog => {
        dialog.submitting = true;
        this.$store
          .dispatch("offers/POST_REQUEST", {
            endpoint: "delete",
            params: { id: offer.id }
          })
          .then(() => {
            this.triggerActionOutcomeAlert("delete", true);
            dialog.hide();
            this.$router.push({ path: "/manage/offers" });
          })
          .catch(() => {
            this.triggerActionOutcomeAlert("delete", false);
          })
          .finally(() => {
            dialog.submitting = false;
          });
      });
    },
    saveOfferTagsLibrary() {
      this.offerTagsLibraryEditor.submitting = true;
      this.$store
        .dispatch("offers/tagsLibrary/POST_REQUEST", {
          params: {
            tags: this.offerTagsLibraryEditor.tags.map(tagData => {
              return tagData.tag;
            }),
            deactivate_remaining: 1
          }
        })
        .then(() => {
          this.triggerActionOutcomeAlert("save", true);
        })
        .catch(() => {
          this.triggerActionOutcomeAlert("save", false);
        })
        .finally(() => {
          this.$store
            .dispatch("offers/tagsLibrary/POPULATE_LIST")
            .finally(() => {
              this.tagsOptions = this.generateSelectOptionsArray(
                this.$store.state.offers.tagsLibrary.list,
                "tag",
                "id"
              );
              this.offerTagsLibraryEditor.submitting = false;
              this.offerTagsLibraryEditor.show = false;
            });
        });
    },
    promptOfferTagsLibraryEditor() {
      Dialog.create({
        component: OfferTagsLibraryEditorModal,
        componentProps: {
          addOnly: true
        }
      }).onOk(dialog => {
        this.tagsOptions = this.generateSelectOptionsArray(
          this.$store.state.offers.tagsLibrary.list,
          "tag",
          "id"
        );
        dialog.submitting = false;
        // dialog.hide();
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
